import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from '../shared/services/cookie.service';
import * as convertKeys from 'convert-keys';

@Injectable({
  providedIn: 'root',
})
export class ResourceService<T> {
  private apiLocation: string;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {
    this.apiLocation = this.cookieService.getMarketsMock() ? 'http://wiremock:8888/v1' : '/api/v1';
  }

  private static handleError(error: HttpErrorResponse): Observable<never> {
    const result = JSON.parse(error?.error?.detail?.substring(6, error.error.detail.length))?.detail;
    throw result;
  }

  get(path: string, params?: HttpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiLocation}/${path}`, { params })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(ResourceService.handleError),
      );
  }

  getById(path: string, id: string | number, params?: HttpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiLocation}/${path}/${id}`, { params })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(ResourceService.handleError),
      );
  }

  getList(path: string, params?: HttpParams): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${this.apiLocation}/${path}`, { params })
      .pipe(
        map(items => items['data'].map(p => convertKeys.toCamel<T>(p))),
        catchError(ResourceService.handleError),
      );
  }

  getPaginated(path: string, index: number, page: number, params?: HttpParams): Observable<T[]> {
    const listParams = new HttpParams().set('limit', index.toString()).set('offset', page.toString());

    return this.httpClient
      .get<T[]>(`${this.apiLocation}/${path}?${listParams.toString()}`, {
        params,
      })
      .pipe(
        map(list => list['data'].map(p => convertKeys.toCamel<T>(p))),
        catchError(ResourceService.handleError),
      );
  }

  add(path: string, resource: T | Partial<T>, params?: HttpParams): Observable<T> {
    return this.httpClient
      .post<T>(`${this.apiLocation}/${path}`, convertKeys.toSnake(resource), {
        params,
      })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(ResourceService.handleError),
      );
  }

  update(path: string, id: string | number, resource: T | Partial<T>, params?: HttpParams): Observable<T> {
    return this.httpClient
      .put<T>(`${this.apiLocation}/${path}/${id}`, convertKeys.toSnake(resource), {
        params,
      })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(ResourceService.handleError),
      );
  }

  delete(path: string, id: string | number, params?: HttpParams): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.apiLocation}/${path}/${id}`, { params })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(ResourceService.handleError),
      );
  }
}
